import { Module } from '@interfaces';
import { MeWithAccessRecordsApiResponse } from '@youscience/user-service-common';

const ECP_MODULE_NAME = 'individual-graduation-plan';

const isECPModuleEnabled = (modules: Module[]) => modules.some((module) => module.name === ECP_MODULE_NAME);

export const isECPEnabled = (
  settingsRefactorEnabled: boolean,
  modules: Module[],
  userData: MeWithAccessRecordsApiResponse,
) => {
  let isEcpEnabled = false;

  const productAvailability = userData.access.some((access) => access.tenant.productAvailability?.ecp);

  const conditionSettingsRefactorEnabled = settingsRefactorEnabled && productAvailability;
  const conditionSettingsRefactorDisabled = !settingsRefactorEnabled && isECPModuleEnabled(modules);

  if (conditionSettingsRefactorEnabled || conditionSettingsRefactorDisabled) {
    isEcpEnabled = true;
  }

  return isEcpEnabled;
};
