import { DISCOVERY_API_URL } from '@constants/externalRoutes';
import { Module, ModulesResponse } from '@interfaces';
import api from '../utils/api';

const formatModules = ({ data: { data } }: ModulesResponse) => {
  let modules: Module[] = [];

  if (data?.sections && data.tiles) {
    data.sections.forEach((section: string) => {
      modules = modules.concat(data?.tiles[section]);
    });
  }

  return modules;
};

const get = async (): Promise<Module[]> => {
  const response = await api(`${DISCOVERY_API_URL}/modules`);

  return formatModules(response);
};

const ModulesService = { get };

export default ModulesService;
